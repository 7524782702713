import React, { FC, useState, useEffect } from "react";
import WidgetHeading1 from "./WidgetHeading1";
import Tag from "shared/Tag/Tag";

export interface WidgetTagsProps {
  className?: string;
  category?: string;
}

const WidgetTags: FC<WidgetTagsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  category,
}) => {
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/posts/tags`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTags(result);
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };

    fetchTags();
  }, [category]);
  return (
    <div
      className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetTags"
    >
      <WidgetHeading1
        title="🏷 Discover more tags"
        viewAll={{ label: "View all", href: "/blog-list" }}
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        {tags
            .filter((_, i) => i < 14)
            .map((tag) => (
          <Tag className="mr-2 mb-2" key={tag.id} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export default WidgetTags;
