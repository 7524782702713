import React, { FC } from "react";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Button from "shared/Button/Button";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
      <div
          className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
          data-nc-id="SectionSubscribe2"
      >
        <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
          <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
          <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span>
          <ul className="space-y-4 mt-10">
            <li className="flex items-center space-x-4">
              <Badge name="01" className="w-8 text-center" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more knowledge
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge color="red" name="02" className="w-8 text-center" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
            </li>
          </ul>
          <div className="mt-10">
            <Button
                className="rounded-full bg-neutral-900 dark:bg-neutral-100 hover:bg-neutral-700 dark:hover:bg-neutral-300 text-neutral-50 dark:text-neutral-900 transition-colors duration-300"
                sizeClass="px-6 py-3"
                fontSize="text-sm font-medium"
                href="https://support.coursemos.kr/ko/notice/support/view/185"
                targetBlank
            >
              Subscribe Now
              <ArrowUpRightIcon className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </div>
        <div className="flex-grow">
          <NcImage src={rightImg} />
        </div>
      </div>
  );
};

export default SectionSubscribe2;
