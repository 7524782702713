import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth } from "./AuthContext";
import FailModal from 'containers/BlogPage/BlogModal/FailModal';

export interface PageLoginProps {
  className?: string;
}

const AuthLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const success = await login(email, password);
      if (success) {
        const redirectPath = localStorage.getItem('loginRedirect');

        if (redirectPath) {
          localStorage.removeItem('loginRedirect');
          navigate(redirectPath);
        } else {
          navigate("/"); // 기본 경로로 이동 (홈 페이지 등)
        }
        //navigate("/account"); // 로그인 성공 시 계정 페이지로 이동
      } else {
        setModalMessage("Please check your credentials.");
        setIsModalOpen(true); // Open the modal on failure
      }
    } catch (error) {
      console.error("Login error:", error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true); // Open the modal on error
    }
  };

  return (
      <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Login
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
              <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
                <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // 이메일 입력 처리
                />
              </label>
              <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
                <Input
                    type="password"
                    className="mt-1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // 비밀번호 입력 처리
                />
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-blue-600 font-semibold hover:underline">
                Create an account
            </Link>
          </span>
          </div>
        </div>
        <FailModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title="Login Status"
            mainMessage={modalMessage}
            subMessage="Please try again."
        />
      </div>
  );
};

export default AuthLogin;
