import React, { FC, useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import Heading2 from "components/Heading/Heading2";
import BlogFilters from "./BlogFilters";
import BlogListCard from "./Card";

export interface SectionBodyProps {
    className?: string;
    category?: string;
    searchTerm?: string;
}

const SectionBody: FC<SectionBodyProps> = ({ className = "" }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [data, setData] = useState<any[]>([]);

    const category = location.state?.category || null;
    const searchTerm = searchParams.get("search") || "";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/posts/latest`);
                if (searchTerm) {
                    url.searchParams.append("search", searchTerm);
                }
                if (category) {
                    url.searchParams.append("category", category);
                }

                const response = await fetch(url.toString());
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchData();
    }, [category, searchTerm]);

    const handleCategoryChange = (newCategory: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());

        // navigate 함수 호출 시 searchTerm을 URL에 그대로 유지
        navigate({
            pathname: location.pathname,
            search: updatedSearchParams.toString(),
        }, {
            state: { category: newCategory }, // category는 새로 변경된 값 사용
            replace: true,
        });
    };

    const resultMessage = (resultCount: number) => {
        switch (resultCount) {
            case 0:
                return 'No results found';
            case 1:
                return 'Just 1 post for you';
            case 2:
            case 3:
                return `${resultCount} posts to Explore`;
            default:
                return `Showing ${resultCount} Awesome posts`;
        }
    };

    return (
        <div className={`nc-SectionGridFilterCard ${className}`}>
            <Heading2
                heading={category  || "All Posts"}
                subHeading={
                    <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                        {resultMessage(data.length)}
                    </span>
                }
            />
            <div className="mb-8 lg:mb-11">
                <BlogFilters category={category  || ""} onCategoryChange={handleCategoryChange} />
            </div>
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {data.map((post) => (
                    <BlogListCard key={post.id} data={post} />
                ))}
            </div>
        </div>
    );
};

export default SectionBody;