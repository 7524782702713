import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";

export interface BlogPostCardProps {
    className?: string;
    size?: "default" | "small";
    data: any;
}

const BlogPostCard: FC<BlogPostCardProps> = ({
     size = "default",
     className = "",
     data
 }) => {
    const navigate = useNavigate();
    const {
        featuredImage,
        title,
        href,
        categories,
       /* like,
        date,
        author,
        postType,
        viewdCount,
        commentCount,
        readingTime,*/
    } = data;

    const handleClick = () => {
        navigate(href, { state: { post: data } });
    };

    const renderSliderGallery = () => {
        return (
            <div className="relative w-full rounded-2xl overflow-hidden">
                <div className="aspect-w-16 aspect-h-9 ">
                    <NcImage
                        containerClassName="flex items-center justify-center"
                        className="w-full"
                        src={featuredImage}
                    />
                </div>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <h2
                            className={`  capitalize ${
                                size === "default" ? "text-xl font-semibold" : "text-base font-medium"
                            }`}
                        >
                            <Link
                                to={href}
                                state={{ post: data }}
                                className="line-clamp-2 leading-6 min-h-[3rem]"
                                title={title}
                            >
                                {title}
                            </Link>
                        </h2>
                    </div>
                </div>
                <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex items-center space-x-2 text-neutral-500 dark:text-neutral-400 text-sm">
                    {categories.map((category: any) => (
                        <Badge
                            key={category.id}
                            name={category.category}
                            color={category.color}
                            className="capitalize"
                        />
                    ))}
                </div>
                <div className="flex justify-between items-center">
                    <div className="mt-auto hidden sm:block">
                        <PostCardMeta meta={{ ...data }} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-BlogPostCard group cursor-pointer relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
            data-nc-id="BlogPostCard"
            onClick={handleClick}
        >
            <div className="flex flex-col">
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </div>
    );
};

export default BlogPostCard;