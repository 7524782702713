import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/AuthLogin/AuthContext";
import BlogEditor from "./BlogEditor";

const BlogWrite: React.FC = () => {
    const navigate = useNavigate();
    const { getCookie } = useAuth();

    const handleSubmit = async (formData: FormData) => {
        const userId = getCookie('userId');
        if (userId) {
            formData.append('userId', userId);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            navigate('/blog');
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return <BlogEditor onSubmit={handleSubmit} submitButtonText="Publish" />;
};

export default BlogWrite;