import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { NavItemType } from "shared/Navigation/NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { NAVIGATION } from "data/navigation";
import { PencilSquareIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { ArrowRightStartOnRectangleIcon, ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { useAuth } from "components/AuthLogin/AuthContext";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  onClickClose,
}) => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = () => {
    localStorage.setItem('loginRedirect', location.pathname);
    navigate('/login');
  };

  const _renderNavigation = () => {
    return (
        <ul className="flex flex-col space-y-1">
          {NAVIGATION.map((item) => (
              <li key={item.id}>
                <NavLink
                    to={item.href}
                    className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                >
            <span className="py-2.5 pr-3 block w-full">
              {item.name}
            </span>
                </NavLink>
              </li>
          ))}
        </ul>
    );
  };

  return (
      <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
        <div className="py-6 px-5">
          <Logo />
          <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span>

            <div className="flex flex-col space-y-4 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex space-x-1">
                  {isAuthenticated && (
                      <>
                        <Link
                            to="/blog-write"
                            className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                            title="Write a blog post"
                        >
                          <PencilSquareIcon className="h-5 w-5 sm:h-6 sm:w-6"/>
                        </Link>
                        <Link
                            to="/account"
                            className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                            title="Update user profile"
                        >
                          <UserCircleIcon className="h-5 w-5 sm:h-6 sm:w-6"/>
                        </Link>
                      </>
                  )}
                  <SwitchDarkMode
                      className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                  />

                  <div className="flex justify-center">
                    {isAuthenticated ? (
                        <button
                            onClick={logout}
                            className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                        >
                          <ArrowRightStartOnRectangleIcon className="h-6 w-6"/>
                        </button>
                    ) : (
                        <button
                            onClick={handleLogin}
                            className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                        >
                          <ArrowLeftEndOnRectangleIcon className="h-6 w-6"/>
                        </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose}/>
        </span>
        </div>
        <ul className="flex flex-col py-6 px-2 space-y-1">
          {_renderNavigation()}
        </ul>
      </div>
  );
};
export default NavMobile;
