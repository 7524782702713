import {
  HomeIcon,
  UserCircleIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PathName } from "routers/types";
import BlogMenuBar from "./BlogMenuBar";

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
  onClick?: () => void;
}

const FooterNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    setShowBackButton(location.pathname !== "/");
  }, [location]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const NAV: NavItem[] = [
    {
      name: showBackButton ? "Back" : "Home",
      link: showBackButton ? undefined : "/",
      icon: showBackButton ? ArrowLeftCircleIcon : HomeIcon,
      onClick: showBackButton ? handleGoBack : undefined,
    },
    {
      name: "Log in",
      link: "/login",
      icon: UserCircleIcon,
    },
    {
      name: "Menu",
      icon: BlogMenuBar,
    },
  ];

  return (
      <div className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700">
        <div className="w-full max-w-lg flex justify-between mx-auto text-sm text-center">
          {NAV.map((item, index) => {
            const active = item.link ? location.pathname === item.link : false;
            const content = (
                <>
                  <div className="w-6 h-6 mx-auto mb-1 relative">
                    <item.icon
                        className={`w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                            active ? "text-red-600" : ""
                        }`}
                    />
                  </div>
                  <span className="text-[11px] leading-none">{item.name}</span>
                </>
            );

            const itemClass = `flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 w-1/3 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
            }`;

            return item.link ? (
                <Link
                    key={index}
                    to={item.link}
                    className={itemClass}
                >
                  {content}
                </Link>
            ) : (
                <div
                    key={index}
                    onClick={item.onClick}
                    className={`${itemClass} cursor-pointer`}
                >
                  {content}
                </div>
            );
          })}
        </div>
      </div>
  );
};

export default FooterNav;