import React from 'react';
import NcModal from "shared/NcModal/NcModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { XCircleIcon } from "@heroicons/react/24/outline";

interface FailModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    mainMessage: string;
    subMessage: string;
}

const FailModal: React.FC<FailModalProps> = ({ isOpen, onClose, title, mainMessage,subMessage }) => {
    return (
        <NcModal
            isOpenProp={isOpen}
            onCloseModal={onClose}
            contentExtraClass="max-w-screen-sm"
            modalTitle={title}
            renderContent={() => (
                <div className="p-6 text-center">
                    <div className="mb-5 flex justify-center">
                        <XCircleIcon className="h-12 w-12 text-red-500" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2">{mainMessage}</h2>
                    <p className="text-neutral-500 dark:text-neutral-400 mb-6">
                        {subMessage}
                    </p>
                    <div className="flex justify-center mt-6">
                        <ButtonPrimary
                            className="bg-red-500 hover:bg-red-600"
                            sizeClass="px-6 py-2.5"
                            onClick={onClose}
                        >
                            Close
                        </ButtonPrimary>
                    </div>
                </div>
            )}
        />
    );
};

export default FailModal;