import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
/*import WidgetCategories from "./WidgetCategories";*/
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";
import { useNavigate } from 'react-router-dom';

export interface SectionLatestPostsProps {
  className?: string;
  postCardName?: "card3";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
    className = "",
    }) => {
  const [apiPosts, setApiPosts] = useState<PostDataType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/latest`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setApiPosts(data);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };

    fetchPosts();
  }, []);

  return (
      <div className={`nc-SectionLatestPosts relative ${className}`}>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
            <Heading>Latest Articles 🎈</Heading>
            <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
              {apiPosts
                  .filter((_, i) => i < 3 && i >= 0)
                  .map((item, index) => (
                  <Card3 key={index} post={item} />
              ))}
            </div>
            <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <ButtonPrimary onClick={() => navigate('/blog-list')}>Show me more</ButtonPrimary>
            </div>
          </div>
          <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
            <WidgetTags />
            {/*<WidgetCategories />*/}
            <WidgetPosts />
          </div>
        </div>
      </div>
  );
};

export default SectionLatestPosts;
