//import { DEMO_POSTS } from "data/posts";
//import { PostDataType } from "data/types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BlogComment from "./BlogComment";
import NcImage from "shared/NcImage/NcImage";
//import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import DeleteModal from "containers/BlogPage/BlogModal/DeleteModal";
import { useAuth } from "components/AuthLogin/AuthContext";

interface Comment {
  id: number;
  user: {
    id: number;
    name: string;
    avatar: string;
  };
  comment: string;
  created_at: string;
  parent_id: number | null;
}

const BlogSingle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, getCookie } = useAuth();
  const { post } = location.state || {};
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState("");
  const [replyToId, setReplyToId] = useState<number | null>(null);

  useEffect(() => {
    if (post) {
      const fetchCount = async () => {
        try {
          await fetchComments();

          const updatedPost = { ...post, type: 'view' };
          const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/count`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ post: updatedPost })
          });

          if (!response.ok) {
            throw new Error('Failed to increment view count');
          }

        } catch (err) {

        }
      };

      fetchCount();
    } else {
      navigate('/blog');
    }
  }, [post]);

  if (!post) {
    return null; // 리디렉션되기 전까지 아무것도 렌더링하지 않음
  }

  const handleModify = () => {
    navigate('/blog-modify', { state: { post } });
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData.message);
        console.error("Detailed error:", errorData.error);
        throw new Error(errorData.message || "Delete operation failed");
      }

      //const responseData = await response.json();
      navigate('/blog');
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comments`);
      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }
      const data: Comment[] = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleCommentSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userId = getCookie('userId');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          comment: newComment,
          user_id: userId,
          content_id: post.id,
          parent_id: replyToId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit comment');
      }

      const data: Comment = await response.json();
      setComments([...comments, data]);
      setNewComment("");
      setReplyToId(null);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleCommentDelete = async (commentId: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comments/${commentId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete comment');
      }

      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Writer" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {post.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {post.desc}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div
                className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                  containerClassName="flex-shrink-0"
                  sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
                  imgUrl={post.author.avatar}
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {post.author.displayName}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {post.date}
                  </span>
                  {/*<span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>*/}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-3 sm:mt-0 sm:ml-3 w-full">
              {/*<SocialsList className="mr-3"/>*/}
              {isAuthenticated && (
                  <>
                    <ButtonPrimary
                        className="bg-blue-500 hover:bg-blue-700"
                        sizeClass="px-4 py-2 sm:px-5"
                        onClick={handleModify}
                    >
                      Modify
                    </ButtonPrimary>
                    <ButtonPrimary
                        className="bg-red-500 hover:bg-red-700 ml-2"
                        sizeClass="px-4 py-2 sm:px-5"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                      Delete
                    </ButtonPrimary>
                  </>
              )}
              {/* Delete 모달 */}
              <DeleteModal
                  isOpen={isDeleteModalOpen}
                  onClose={() => setIsDeleteModalOpen(false)}
                  onConfirm={async () => {
                    await handleDelete();
                    setIsDeleteModalOpen(false);
                  }}
              />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
        <div
            id="single-entry-content"
            className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            dangerouslySetInnerHTML={{__html: post.content}}
        >
        </div>
    );
  };

  const renderTags = () => {

    const tags = post.categories;

    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {tags.length > 0 && (
            tags.map((category: any, index: number) => (
                <button
                    key={index}
                    style={{ cursor: 'default' }}
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                >
                  {category.category}
                </button>
            ))
        )}
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar
              sizeClass="w-11 h-11 md:w-24 md:h-24"
              imgUrl={post.author.avatar}
          />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">{post.author.displayName}</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              {post.author.desc
                  ? post.author.desc
                  : "The tech world moves fast, with innovation pushing boundaries every day."}
              {/*<a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>*/}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
        <div className="max-w-screen-md mx-auto pt-5">
          <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            Comment ({comments.length})
          </h3>
          {/*<h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            {replyToId ? "Reply to comment" : "Add a comment"}
          </h3>*/}
          <form className="nc-SingleCommentForm mt-5" onSubmit={handleCommentSubmit}>
            <Textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
            />
            <div className="mt-2 space-x-3">
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
              <ButtonSecondary  type="button" onClick={() => {
                setNewComment("");
                setReplyToId(null);
              }}>Cancel</ButtonSecondary >
            </div>
          </form>
        </div>
    );
  };

  const renderCommentLists = () => {
    const topLevelComments = comments.filter(comment => comment.parent_id === null);

    const renderComment = (comment: Comment) => {
      const replies = comments.filter(reply => reply.parent_id === comment.id);

      return (
          <li key={comment.id}>
            <BlogComment
                comment={comment}
                onDelete={() => handleCommentDelete(comment.id)}
                onReply={() => setReplyToId(comment.id)}
            />
            {replies.length > 0 && (
                <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                  {replies.map(renderComment)}
                </ul>
            )}
          </li>
      );
    };

    return (
        <div className="max-w-screen-md mx-auto">
          <ul className="nc-SingleCommentLists space-y-5">
            {topLevelComments.map(renderComment)}
          </ul>
        </div>
    );
  };

  /*const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };*/

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 max-w-4xl mx-auto"
        src={post.featuredImage}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        {/*<div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">

            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}

          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default BlogSingle;
