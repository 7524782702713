import React, { FC, useEffect, useState } from "react";
import { PostDataType } from "data/types";
import Card12 from "./Card12";
import Card13 from "./Card13";

export interface SectionMainPostsProps {

}

const SectionMainPosts: FC<SectionMainPostsProps> = () => {
    const [apiPosts, setApiPosts] = useState<PostDataType[]>([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/main`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setApiPosts(data);
            } catch (error) {
                console.error("Failed to fetch posts:", error);
            }
        };

        fetchPosts();
    }, []);
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        {/*{posts[0] && <Card12 post={posts[0]} />}*/}
          {apiPosts[0] && <Card12 post={apiPosts[0]} />}

          <div className="grid gap-6 md:gap-8">
          {apiPosts
            .filter((_, i) => i < 4 && i > 0)
            .map((item, index) => (
              <Card13 key={index} post={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMainPosts;
