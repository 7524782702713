import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeader from "./SectionHeader";
import SectionBody from "./SectionBody";
import SectionFooter from "./SectionFooter";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
//import heroRightImage from "images/hero-right-car.png";
import heroRightImage from "images/img.png";

export interface ListingCarPageProps {
  className?: string;
}

const ListingCarPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { category } = location.state || {};
  const searchTerm = searchParams.get("search") || undefined;

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeader
          rightImage={heroRightImage}
          currentPage="Cars"
          currentTab="Cars"
          listingType={
            <>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        <SectionBody category={category} searchTerm={searchTerm} className="pb-24 lg:pb-28" />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionFooter
            heading="Explore top articles 📝"
            subHeading="Stay ahead with cutting-edge tech insights"
            categoryCardType="card4"
            itemPerRow={4}
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ListingCarPage;
