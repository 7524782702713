import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

interface MobileSearchButtonProps {
  onSearch: (searchTerm: string) => void;
}

const MobileSearchButton: React.FC<MobileSearchButtonProps> = ({ onSearch }) => {
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSearchMode) {
      inputRef.current?.focus();
    }
  }, [isSearchMode]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearch(searchTerm);
      navigate(`/blog-list?search=${encodeURIComponent(searchTerm)}`);
      setIsSearchMode(false);
      setSearchTerm("");
    }
  };

  const handleButtonClick = () => {
    setIsSearchMode(true);
  };

  const handleClose = () => {
    setIsSearchMode(false);
    setSearchTerm("");
  };

  return (
      <div className="relative w-full h-14">
        <div className={`absolute inset-0 flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 rounded-full shadow-lg ${isSearchMode ? 'bg-white dark:bg-neutral-800' : ''}`}>
          {isSearchMode ? (
              <form onSubmit={handleSubmit} className="flex items-center justify-between w-full h-full">
                <input
                    ref={inputRef}
                    type="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full bg-transparent focus:outline-none dark:text-white border-none"
                    style={{
                      WebkitAppearance: 'none',
                      boxShadow: 'none',
                    }}
                    placeholder="Type and press enter"
                />
                <div className="flex items-center space-x-2">
                  {/*<button
                      type="submit"
                      className="text-neutral-700 dark:text-neutral-300 p-2"
                  >
                    <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-300" />
                  </button>*/}
                  <button
                      type="button"
                      onClick={handleClose}
                      className="text-neutral-700 dark:text-neutral-300 p-2"
                  >
                    <ArrowLeftIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-300" />
                  </button>
                </div>
              </form>
          ) : (
              <button
                  onClick={handleButtonClick}
                  className="flex items-center justify-between w-full h-full"
              >
                <div className="flex items-center">
                  <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-300 mr-3" />
                  <div className="text-left overflow-hidden">
                    <span className="block font-medium text-sm">What is?</span>
                    <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400">
                      <span className="line-clamp-1">Explore topics • Find insights • Discover ideas</span>
                    </div>
                  </div>
                </div>
                <span className="flex items-center justify-center w-9 h-9 rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
              <svg viewBox="0 0 16 16" aria-hidden="true" role="presentation" focusable="false" className="block w-4 h-4" fill="currentColor">
                <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
            </span>
              </button>
          )}
        </div>
      </div>
  );
};

export default MobileSearchButton;