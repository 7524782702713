import React from "react";

interface BlogPreviewProps {
    content: string;
}

const BlogPreview: React.FC<BlogPreviewProps> = ({ content }) => {
    return (
        <div className="blog-preview" style={{padding: "20px", borderRadius: "10px"}}>
            <div
                id="single-entry-content"
                className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                dangerouslySetInnerHTML={{__html: content}}
            >
            </div>
        </div>
    );
};

export default BlogPreview;
