import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'components/AuthLogin/AuthContext';
import { HeartIcon } from '@heroicons/react/24/outline';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
        <div className="flex justify-center items-center h-screen">
          <HeartIcon className="w-16 h-16 animate-spin text-gray-500" />
        </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to the login page, but save the current location
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;