import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogEditor from "./BlogEditor";

const BlogModify: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { post } = location.state || {};

    const handleSubmit = async (formData: FormData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            navigate(`/blog-single`, { state: { post: responseData.contents } });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <BlogEditor
            initialData={post}
            onSubmit={handleSubmit}
            submitButtonText="Update"
        />
    );
};

export default BlogModify;