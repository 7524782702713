import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import Input from "../../shared/Input/Input";
import FormItem from "../PageAddListing1/FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom";
import { ResizableBox } from "react-resizable";
import BlogPreview from "./BlogPreview";

import "react-resizable/css/styles.css";
import "react-quill/dist/quill.snow.css";
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

// Quill 에디터에 이미지 액션과 이미지 포맷을 등록
Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

// Quill의 코드 블록 포맷에 대한 타입 정의
type QuillCodeBlock = {
    new (): {
        domNode: HTMLElement;
    };
    create(value: string): HTMLElement;
    formats(domNode: HTMLElement): string | undefined;
} & {
    create(value: string): HTMLElement;
    formats(domNode: HTMLElement): string | undefined;
};

// 코드 블록 포맷을 확장하여 구문 강조 기능을 추가
const CodeBlock = Quill.import('formats/code-block') as QuillCodeBlock;

class HighlightCodeBlock extends CodeBlock {
    static create(value: string) {
        const domNode = super.create(value);
        domNode.classList.add('hljs');
        return domNode;
    }

    static formats(domNode: HTMLElement) {
        const className = domNode.className;
        if (className) {
            const match = className.match(/language-(\w+)/);
            return match ? match[1] : 'plaintext';
        }
        return 'plaintext';
    }
}

Quill.register('formats/code-block', HighlightCodeBlock, true);

// base64 데이터 URL을 File 객체로 변환하는 헬퍼 함수
const dataURLtoFile = (dataUrl: string, filename: string): File | null => {
    if (!dataUrl.startsWith("data:image/")) return null;
    const arr = dataUrl.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : null;
    if (!mime) return null;
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
};

interface BlogEditorProps {
    initialData?: {
        title?: string;
        desc?: string;
        content?: string;
        categories?: { category: string }[];
        featuredImage?: string;
    };
    onSubmit: (formData: FormData) => Promise<void>;
    submitButtonText: string;
}

const BlogEditor: React.FC<BlogEditorProps> = ({ initialData, onSubmit, submitButtonText }) => {
    const navigate = useNavigate();
    const quillRef = useRef<ReactQuill>(null);

    const [title, setTitle] = useState(initialData?.title || "");
    const [description, setDescription] = useState(initialData?.desc || "");
    const [content, setContent] = useState(initialData?.content || "");
    const [tags, setTags] = useState<string[]>(initialData?.categories?.map((category: any) => category.category) || []);
    const [tagInput, setTagInput] = useState("");
    const [coverImage, setCoverImage] = useState<File | null>(null);
    const [editorHeight, setEditorHeight] = useState(500);
    const [isHovered, setIsHovered] = useState(false);
    const [isPreview, setIsPreview] = useState(false);

    const uploadImage = useCallback(async (file: File): Promise<string> => {
        const formData = new FormData();
        formData.append('image', file);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/upload-image`, { method: 'POST', body: formData });
        if (!response.ok) throw new Error('Image upload failed');
        const data = await response.json();
        return data.url;
    }, []);

    // 이미지를 선택하고 에디터에 삽입하는 함수
    const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            const file = input.files?.[0];
            if (file) {
                const url = await uploadImage(file);
                const quill = quillRef.current?.getEditor();
                if (quill) {
                    const range = quill.getSelection(true);
                    quill.insertEmbed(range.index, 'image', url);
                }
            }
        };
    }, [uploadImage]);

    const handleContentChange = (value: string) => setContent(value);
    const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setTagInput(e.target.value);
    const handleAddTag = () => {
        if (tagInput.trim()) {
            setTags([...tags, tagInput.trim()]);
            setTagInput("");
        }
    };
    const handleRemoveTag = (index: number) => setTags(tags.filter((_, i) => i !== index));
    const handleCoverImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) setCoverImage(file);
    };

    const handleResize = (event: any, { size }: { size: { height: number } }) => setEditorHeight(size.height);
    const togglePreview = () => setIsPreview(!isPreview);
    const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File | null> => {
        return new Promise((resolve) => {
            const img = new Image();
            const reader = new FileReader();

            // 파일이 로드되면 이미지를 데이터 URL로 읽어옴
            reader.onload = (e) => {
                img.src = e.target?.result as string;
            };

            // 이미지를 로드한 후 사이즈 조정
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;
                let { width, height } = img;

                // 너비와 높이 비율을 유지하면서 사이즈를 제한
                if (width > maxWidth || height > maxHeight) {
                    const aspectRatio = width / height;
                    if (width > height) {
                        width = maxWidth;
                        height = maxWidth / aspectRatio;
                    } else {
                        height = maxHeight;
                        width = maxHeight * aspectRatio;
                    }
                }

                // 리사이즈된 이미지를 캔버스에 그림
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // 캔버스를 Blob으로 변환한 후 File로 변환
                canvas.toBlob((blob) => {
                    if (blob) {
                        const resizedFile = new File([blob], file.name, { type: file.type });
                        resolve(resizedFile);
                    } else {
                        resolve(null);
                    }
                }, file.type);
            };

            reader.readAsDataURL(file);
        });
    };

    // 클립보드에서 붙여넣은 이미지를 처리하는 함수
    useEffect(() => {
        const quill = quillRef.current?.getEditor();
        if (quill) {
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                const ops = delta.ops.map((op) => {
                    if (typeof op.insert === "object" && "image" in op.insert && typeof (op.insert as any).image === "string") {
                        const image = (op.insert as { image: string }).image;
                        //console.log("Inserted image:", image);
                        if (image.startsWith("data:image/")) {
                            const file = dataURLtoFile(image, "clipboard-image.png");
                            if (file) {
                                //console.log("Original file size (in bytes):", file.size);
                                resizeImage(file, 600, 400).then((resizedFile) => { // 예를 들어 800x600으로 리사이즈
                                    if (resizedFile) {
                                        //console.log("Resized file size (in bytes):", resizedFile.size);
                                        uploadImage(resizedFile).then((url) => {
                                            quill.insertEmbed(quill.getSelection(true).index, "image", url);
                                        });
                                    }
                                });
                            }
                            return { insert: "" };
                        }
                        return op;
                    }
                    return op;
                });
                delta.ops = ops;
                return delta;
            });
        }
    }, [uploadImage]);


    // 코드 블록에 구문 강조 기능을 추가
    useEffect(() => {
        const codeBlocks = document.querySelectorAll('pre.ql-syntax');
        codeBlocks.forEach((block) => {
            if (block instanceof HTMLElement) {
                if (!block.dataset.highlighted) {
                    const langClass = Array.from(block.classList).find(cls => cls.startsWith('language-'));
                    const lang = langClass ? langClass.replace('language-', '') : 'plaintext';
                    block.className = block.className.replace(/hljs\S*/g, '').trim();
                    block.classList.add('hljs', `language-${lang}`);
                    hljs.highlightElement(block);
                    block.dataset.highlighted = 'true';
                }
            }
        });
    }, [content]);

    const highlightCodeBlocks = useCallback(() => {
        const codeBlocks = document.querySelectorAll('pre.ql-syntax');
        codeBlocks.forEach((block) => {
            if (block instanceof HTMLElement) {
                if (block.dataset.highlighted === 'true') {
                    return;
                }

                const langClass = Array.from(block.classList).find(cls => cls.startsWith('language-'));
                const lang = langClass ? langClass.replace('language-', '') : 'plaintext';

                block.className = block.className.replace(/hljs\S*/g, '').trim();
                block.classList.add('hljs', `language-${lang}`);
                hljs.highlightElement(block);
                block.dataset.highlighted = 'true';
            }
        });
    }, []);

    useEffect(() => {
        highlightCodeBlocks();
    }, [content, highlightCodeBlocks]);

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'font': [] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image", "code-block"],
                [{ align: [] }, { color: [] }, { background: [] }],
            ],
            handlers: { image: imageHandler },
        },
        syntax: { highlight: (text: string) => hljs.highlightAuto(text).value },
        imageActions: { modules: ['Resize', 'DisplaySize', 'Toolbar'], sizing: { sizes: [{ name: 'small', value: '300' }, { name: 'medium', value: '600' }, { name: 'large', value: '900' }] } },
        imageFormats: {},
    }), [imageHandler]);

    const addTooltips = useCallback(() => {
        const toolbarButtons = document.querySelectorAll('.ql-toolbar button, .ql-toolbar .ql-picker');
        toolbarButtons.forEach(button => {
            if (button instanceof HTMLElement) {
                if (button.querySelector('.tooltip')) return;

                let tooltipText = button.className.split('ql-')[1];
                if (tooltipText) {
                    tooltipText = tooltipText.charAt(0).toUpperCase() + tooltipText.slice(1);

                    const tooltip = document.createElement('div');
                    tooltip.textContent = tooltipText;
                    tooltip.className = 'tooltip absolute bottom-full left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded mb-2 opacity-0 invisible transition-opacity duration-300 pointer-events-none z-10';

                    const arrow = document.createElement('div');
                    arrow.className = 'absolute -bottom-1 left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800';

                    tooltip.appendChild(arrow);

                    button.classList.add('relative');
                    button.appendChild(tooltip);

                    button.addEventListener('mouseenter', () => {
                        tooltip.classList.remove('opacity-0', 'invisible');
                    });
                    button.addEventListener('mouseleave', () => {
                        tooltip.classList.add('opacity-0', 'invisible');
                    });
                }
            }
        });
    }, []);

    useEffect(() => {
        if (!isPreview) {
            const timer = setTimeout(addTooltips, 500);
            return () => clearTimeout(timer);
        }
    }, [addTooltips, isPreview]);

    const formats = [
        "header", "bold", "italic", "underline", "strike", "blockquote",
        "list", "indent", "link", "image", "align", "float", "color", "background",
        'height', 'width', "code-block",
    ];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', content);
        formData.append('tags', JSON.stringify(tags));
        if (coverImage) {
            formData.append('featuredImage', coverImage);
        } 
        await onSubmit(formData);
    };

    const handleAddPresetTag = (presetTag: string) => {
        if (!tags.includes(presetTag)) {
            setTags([...tags, presetTag]);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={`nc-PageAddListing1 px-4 max-w-7xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}>
            <div className="space-y-11">
                <div className="listingSection__wrap">
                    <h2 className="text-2xl font-semibold">{initialData ? "Modify Blog Post" : "Create a New Blog Post"}</h2>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-8"></div>
                    <div className="space-y-8">
                        <FormItem
                            label="Cover Image"
                            desc="Upload a cover image for your blog post"
                        >
                            <div className="mt-5">
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-neutral-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                        <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
                                            <label
                                                htmlFor="cover-image-upload"
                                                className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    id="cover-image-upload"
                                                    name="cover-image-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={handleCoverImageChange}
                                                />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                            PNG, JPG, GIF up to 2MB
                                        </p>
                                    </div>
                                </div>
                                {coverImage && (
                                    <div className="mt-4">
                                        <img
                                            src={URL.createObjectURL(coverImage)}
                                            alt="Cover preview"
                                            className="w-full max-w-xs mx-auto"
                                        />
                                    </div>
                                )}
                                {initialData?.featuredImage && !coverImage && (
                                    <div className="mt-4">
                                        <img
                                            src={initialData.featuredImage}
                                            alt="Current cover"
                                            className="w-full max-w-xs mx-auto"
                                        />
                                    </div>
                                )}
                            </div>
                        </FormItem>
                        <FormItem
                            label="Blog Title"
                            desc="Enter a catchy title for your blog post"
                        >
                            <Input
                                placeholder="Blog title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                aria-required="true"
                            />
                        </FormItem>
                        <FormItem
                            label="Blog Description"
                            desc="Briefly describe the main points or topic of your blog post."
                        >
                            <Input
                                placeholder="Blog description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                aria-required="true"
                            />
                        </FormItem>
                        <FormItem
                            label="Blog Tags"
                            desc="Add relevant tags to categorize your blog post"
                        >
                            <div className="flow-root">
                                <div className="flex flex-wrap -m-1">
                                    {tags.map((tag, index) => (
                                        <span key={index}
                                              className="m-1 bg-neutral-200 dark:bg-neutral-700 px-3 py-1 rounded-full text-sm flex items-center">
                                            #{tag}
                                            <i
                                                className="ml-2 text-xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
                                                onClick={() => handleRemoveTag(index)}
                                            ></i>
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-wrap mt-4">
                                {["Elasticsearch", "Linux", "Laravel"].map((presetTag, index) => (
                                    <button
                                        type="button"
                                        key={index}
                                        className="m-1 bg-neutral-300 dark:bg-neutral-600 px-3 py-1 rounded-full text-sm hover:bg-neutral-400 dark:hover:bg-neutral-500"
                                        onClick={() => handleAddPresetTag(presetTag)}
                                    >
                                        {presetTag}
                                    </button>
                                ))}
                            </div>
                            <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5 pt-3">
                                <Input
                                    className="!h-full"
                                    placeholder="Blog Tag"
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                />
                                <ButtonSecondary className="flex-shrink-0" onClick={handleAddTag}>
                                    <i className="text-xl las la-plus"></i>
                                    <span className="ml-3">Add tag</span>
                                </ButtonSecondary>
                            </div>
                        </FormItem>
                        <FormItem
                            label="Blog Content"
                            desc="Write your blog content using the rich text editor below"
                            className="relative"
                        >
                            <div className="absolute top-0 right-0 z-10">
                                <ButtonSecondary
                                    className="!h-9 !px-4"
                                    onClick={togglePreview}
                                >
                                    {isPreview ? "Edit" : "Preview"}
                                </ButtonSecondary>
                            </div>
                            <div
                                className="mt-8"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{
                                    border: isHovered ? "1px solid skyblue" : "1px solid #e5e7eb",
                                    borderRadius: "0.375rem",
                                }}
                            >
                                {!isPreview ? (
                                    <ResizableBox
                                        width={Infinity}
                                        height={editorHeight}
                                        minConstraints={[Infinity, 200]}
                                        onResize={handleResize}
                                        resizeHandles={['se']}
                                    >
                                        <ReactQuill
                                            ref={quillRef}
                                            theme="snow"
                                            value={content}
                                            onChange={handleContentChange}
                                            modules={modules}
                                            formats={formats}
                                            style={{
                                                height: editorHeight - 42,
                                                marginBottom: "50px",
                                                overflow: 'visible',
                                            }}
                                        />
                                    </ResizableBox>
                                ) : (
                                    <div
                                        className="preview-container bg-white p-4"
                                        style={{
                                            minHeight: '200px',
                                            height: 'auto',
                                        }}
                                    >
                                        <BlogPreview content={content} />
                                    </div>
                                )}
                            </div>
                        </FormItem>
                    </div>
                </div>
                <div className="flex justify-end space-x-5">
                    <ButtonSecondary onClick={() => navigate('/blog')}>Cancel</ButtonSecondary>
                    <ButtonPrimary type="submit">
                        {submitButtonText}
                    </ButtonPrimary>
                </div>
            </div>
        </form>
    );
};

export default BlogEditor;