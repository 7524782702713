import React from 'react';
import NcModal from "shared/NcModal/NcModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  mainMessage: string;
  subMessage: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, title, mainMessage,subMessage  }) => {
  return (
      <NcModal
          isOpenProp={isOpen}
          onCloseModal={onClose}
          contentExtraClass="max-w-screen-sm"
          modalTitle={title}
          renderContent={() => (
              <div className="p-6 text-center">
                <div className="mb-5 flex justify-center">
                  <CheckCircleIcon className="h-12 w-12 text-green-500" />
                </div>
                <h2 className="text-xl font-semibold mb-2">{mainMessage}</h2>
                <p className="text-neutral-500 dark:text-neutral-400 mb-6">
                  {subMessage}
                </p>
                <div className="flex justify-center mt-6">
                  <ButtonPrimary
                      className="bg-primary-500 hover:bg-primary-600"
                      sizeClass="px-6 py-2.5"
                      onClick={onClose}
                  >
                    Got it, thanks!
                  </ButtonPrimary>
                </div>
              </div>
          )}
      />
  );
};

export default SuccessModal;