import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface AuthContextProps {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  getCookie: any;
  setCookie: any;
  removeCookie: any;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = getCookie('token');
    const userId = getCookie('userId');
    if (token && userId) {
      createOrUpdateUser({ userId : userId }).then((userData) => {
        if (userData) {
          setUser(userData);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await axios.post('https://develop.coursemos.co.kr/api/v1/login', new URLSearchParams({
        email,
        password,
      }).toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.data.token) {
        setCookie('token', response.data.token);
        setCookie('userId', response.data.user.id);

        const userData = await createOrUpdateUser(response.data.user);
        if (userData) {
          setUser(userData);
          navigate('/dashboard');
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error('Login failed', error);
      return false;
    }
  };

  const createOrUpdateUser = async (data: any): Promise<any> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: data})
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.user;
    } catch (error) {
      removeCookie('token');
      removeCookie('userId');
      console.error(error);
      return null;
    }
  };

  const logout = () => {
    removeCookie('token');
    removeCookie('userId');
    setUser(null);
    navigate('/login');
  };

  return (
      <AuthContext.Provider value={{ isAuthenticated: !!user, user, loading, login, logout, getCookie, setCookie, removeCookie }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Utility functions to handle cookies
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value}; path=/; max-age=2592000`; // 30 days
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};