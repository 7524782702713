import React from "react";
import NcModal from "shared/NcModal/NcModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { TrashIcon } from "@heroicons/react/24/outline";

interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    mainMessage?: string;
    subMessage?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
     isOpen,
     onClose,
     onConfirm,
     title = "Delete Post",
     mainMessage = "Are you sure you want to delete this post?",
     subMessage = "Once deleted, this action cannot be undone.",
 }) => {
    return (
        <NcModal
            isOpenProp={isOpen}
            onCloseModal={onClose}
            contentExtraClass="max-w-screen-sm"
            modalTitle={title}
            renderContent={() => (
                <div className="p-6 text-center">
                    <div className="mb-5 flex justify-center">
                        <TrashIcon className="h-12 w-12 text-red-500" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2">{mainMessage}</h2>
                    <p className="text-neutral-500 dark:text-neutral-400 mb-6">{subMessage}</p>
                    <div className="flex justify-center mt-6 space-x-4">
                        <ButtonSecondary
                            className="bg-gray-300 hover:bg-gray-400 text-black px-6 py-2.5"
                            onClick={onClose}
                        >
                            Cancel
                        </ButtonSecondary>
                        <ButtonPrimary
                            className="bg-red-500 hover:bg-red-700 px-6 py-2.5"
                            onClick={onConfirm}
                        >
                            Confirm
                        </ButtonPrimary>
                    </div>
                </div>
            )}
        />
    );
};

export default DeleteModal;
