import {PostDataType} from "data/types";
import React, {FC, useEffect, useState} from "react";
import Card3Small from "./Card3Small";
import WidgetHeading1 from "./WidgetHeading1";

export interface WidgetPostsProps {
  className?: string;
  posts?: PostDataType[];
}

const WidgetPosts: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
}) => {
  const [popular, setPopular] = useState<PostDataType[]>([]);

  useEffect(() => {
    const fetchPopular = async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/posts/popular`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setPopular(result);
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };

    fetchPopular();
  }, []);
  return (
    <div
      className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetPosts"
    >
      <WidgetHeading1
        title="🎯 Popular Posts"
        viewAll={{ label: "View all", href: "/blog-list" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {popular.map((post) => (
          <Card3Small
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={post.id}
            post={post}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetPosts;
