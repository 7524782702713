import React, { FC, useEffect, useMemo, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import NextPrev from "shared/NextPrev/NextPrev";
import useNcId from "hooks/useNcId";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
   heading = "Heading of sections",
   subHeading = "Descriptions for sections",
   className = "",
   itemClassName = "",
   categoryCardType = "card3",
   itemPerRow = 5,
   sliderStyle = "style1",
   uniqueClassName,
 }) => {
  const [categories, setCategories] = useState<TaxonomyType[]>([]);
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  const MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: { perView: itemPerRow - 1 },
        1024: { gap: 20, perView: itemPerRow - 1 },
        768: { gap: 20, perView: itemPerRow - 2 },
        640: { gap: 20, perView: itemPerRow - 3 },
        500: { gap: 20, perView: 1.3 },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/explore`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      MY_GLIDEJS.mount();
    }
  }, [MY_GLIDEJS, categories]);

  const handleNext = () => {
    MY_GLIDEJS.go(">");
  };

  const handlePrev = () => {
    MY_GLIDEJS.go("<");
  };

  const renderCard = (item: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={item} />;
      case "card4":
        return <CardCategory4 taxonomy={item} />;
      case "card5":
        return <CardCategory5 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
      <div className={`nc-SectionSliderNewCategories ${className}`}>
        <div className={`${UNIQUE_CLASS} flow-root`}>
          <Heading
              desc={subHeading}
              hasNextPrev={sliderStyle === "style1"}
              isCenter={sliderStyle === "style2"}
          >
            {heading}
          </Heading>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {categories.map((item, index) => (
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    {renderCard(item, index)}
                  </li>
              ))}
            </ul>
          </div>

          {sliderStyle === "style2" && (
              <NextPrev
                  className="justify-center mt-16"
                  onClickNext={handleNext}
                  onClickPrev={handlePrev}
              />
          )}
        </div>
      </div>
  );
};

export default SectionSliderNewCategories;
