import React, { useState } from "react";
import { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import DeleteModal from "containers/BlogPage/BlogModal/DeleteModal";
import { useAuth } from "components/AuthLogin/AuthContext";
//import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";

export interface Comment {
  id: number;
  user: {
    id: number;
    name?: string;
    displayName?: string;
    avatar: string;
  };
  comment: string;
  created_at: string;
  replies?: Comment[];
}

export interface BlogCommentProps {
  comment: Comment;
  isSmall?: boolean;
  onDelete: () => void;
  onReply: (parentId: number) => void;
}

const BlogComment: FC<BlogCommentProps> = ({ comment, isSmall, onDelete, onReply }) => {
  const { isAuthenticated } = useAuth();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteConfirm = () => {
    onDelete();
    setIsDeleteModalOpen(false); // 모달 닫기
  };

  return (
      <div className="nc-CommentCard flex">
        <div className="pt-1">
          <Avatar
              sizeClass={`w-6 h-6 ${!isSmall ? "sm:h-8 sm:w-8 " : ""}`}
              imgUrl={comment.user.avatar}
          />
        </div>
        <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          <div className="relative flex items-center pr-6">
            <button
                className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"
                style={{ pointerEvents: 'none' }}
                //href={`/user/${comment.user.id}`}
            >
              {comment.user.displayName}
            </button>
            <span className="mx-2">·</span>
            <span className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
            {new Date(comment.created_at).toLocaleDateString()}
          </span>
          </div>
          <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
          {comment.comment}
        </span>
          <div className="flex items-center space-x-2">
            {isAuthenticated && (
                <>
                  {/*<button
                      className="inline-flex items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-100 dark:text-neutral-200 dark:bg-neutral-800 px-3 h-8 hover:bg-teal-50 hover:text-teal-600 dark:hover:text-teal-500 focus:outline-none"
                      title="Reply"
                  >
                    <ArrowUturnLeftIcon className="h-5 w-4 mr-2"/>
                    <span className="text-xs leading-none text-neutral-900 dark:text-neutral-200">
                      Reply
                    </span>
                  </button>*/}
                  <button
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="inline-flex items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-100 dark:text-neutral-200 dark:bg-neutral-800 px-3 h-8 hover:bg-red-50 hover:text-red-600 dark:hover:text-red-500 focus:outline-none"
                      title="Delete"
                  >
                    <TrashIcon className="h-5 w-4 mr-2"/> {/* TrashIcon 사용 */}
                    <span className="text-xs leading-none text-neutral-900 dark:text-neutral-200">
                      Delete
                    </span>
                  </button>
                </>
            )}

          </div>
        </div>
        <DeleteModal
            isOpen={isDeleteModalOpen}
            title="Delete Comment"
            mainMessage="Are you sure you want to delete this comment?"
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirm}
        />
      </div>
  );
};

export default BlogComment;