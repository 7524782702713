import React, { Fragment, FC, useState, useEffect } from "react";
import Select from "shared/Select/Select";

interface BlogFiltersProps {
  category?: string;
  onCategoryChange?: (category: string) => void;
}

const BlogFilters: FC<BlogFiltersProps> = ({ category, onCategoryChange  }) => {
  const [tags, setTags] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(category);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/posts/tags`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setTags(result);
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };

    fetchTags();
  }, []);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    if (onCategoryChange) {
      onCategoryChange(selectedValue);
    }
  };

  const renderTabsTypeOfCategories = () => {
    return (
          <>
            <Select
                value={selectedCategory || ""}
                onChange={handleCategoryChange}
            >
              <option value="">All Categories</option>
              {tags.map((tag) => (
                  <option value={tag.name} key={tag.id}>
                    {tag.name}
                  </option>
              ))}
            </Select>
          </>
    );
  };

  return (
      <div className="flex lg:space-x-4">
        <div className="flex space-x-4">
          {renderTabsTypeOfCategories()}
        </div>
      </div>
  );
};

export default BlogFilters;
