import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useAuth } from "components/AuthLogin/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PencilSquareIcon, UserCircleIcon } from "@heroicons/react/24/outline";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSearch = (searchTerm: string) => {
    // Perform a search or update state based on the search term
  };

  const handleLogin = () => {
    localStorage.setItem('loginRedirect', location.pathname);
    navigate('/login');
  };

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {isAuthenticated && (
                <Link
                    to="/blog-write"
                    className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
                    title="Write a blog post"
                >
                  <PencilSquareIcon className="h-6 w-6" />
                </Link>
            )}
            {isAuthenticated && (
                <Link
                    to="/account"
                    className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
                    title="Write a blog post"
                >
                  <UserCircleIcon className="h-6 w-6" />
                </Link>
            )}
            <SwitchDarkMode />
            <SearchDropdown onSearch={handleSearch} />
            <div className="px-1" />

            {isAuthenticated ? (
                <ButtonPrimary onClick={logout}>Logout</ButtonPrimary>
            ) : (
                <ButtonPrimary onClick={handleLogin}>Login</ButtonPrimary>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
