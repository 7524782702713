import Logo from "shared/Logo/Logo";
//import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { LinkIcon } from "@heroicons/react/24/outline";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Service",
    menus: [
      { href: "https://service.coursemos.co.kr/", label: "AI" },
      { href: "https://service.coursemos.co.kr/", label: "Big Data" },
      { href: "https://service.coursemos.co.kr/", label: "Notification" },
    ],
  },
  {
    id: "1",
    title: "Develop",
    menus: [
      { href: "https://dev.coursemos.co.kr/", label: "Intro" },
      { href: "https://develop.coursemos.co.kr/api/documentation", label: "Swagger" },
    ],
  },
  {
    id: "2",
    title: "Docs",
    menus: [
      { href: "https://docs.coursemos.co.kr/", label: "Document" },
    ],
  }

];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="inline-flex items-center text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
                <LinkIcon className="ml-2 h-3 w-3" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex flex-col items-start md:col-span-3">
            {/*<p className="text-neutral-600 dark:text-neutral-300 text-xs leading-2">
              (08378) 서울시 구로구 디지털로 34길 27
            </p>
            <p className="text-neutral-600 dark:text-neutral-300 text-xs leading-6">
              대륭포스트타워 3차 6층 601호
            </p>*/}
            <p className="text-neutral-400 dark:text-neutral-400 text-xs mt-2">
              ©Ubion, Inc. All rights reserved
            </p>

            {/*<SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />*/}
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
